<template>
  <div class="all_main_wrap">
    <div class="all_header_box">
      <div class="sider_left">
        <span class="title">
          <router-link
            class="crumbs_item"
            tag="a"
            to="/admin/OrderConfig/RefundList"
            >订单退款 /
          </router-link>
          <span class="crumbs_item crumbs_last">新建</span>
        </span>
      </div>
    </div>
    <a-timeline>
      <a-timeline-item>
        <div class="headTitle">
          <p>条件查询</p>
        </div>
        <div class="find content">
          <a-input v-model.trim="orderCode" placeholder="请输入订单编号" />
          <a-button type="primary" :loading="loading" @click="search()">搜索</a-button>
        </div>
      </a-timeline-item>
      <a-timeline-item v-if="orderInfo">
        <div class="headTitle">
          <p>查询信息</p>
        </div>
        <div class="info content">
          <div class="option">姓名：{{orderInfo.userName}}</div>
          <div class="option">手机号：{{orderInfo.mobile}}</div>
          <div class="option">订单编号：{{orderInfo.orderCode}}</div>
          <div class="option">支付交易号：{{orderInfo.tradeId || '-'}}</div>
          <div class="option">支付方式：{{orderInfo.payMethod == 1 ? '微信' : orderInfo.payMethod == 2 ? '支付宝' : orderInfo.payMethod == 3 ? '对公转账' : ''}}</div>
          <div class="option">支付时间：{{orderInfo.paymentTime}}</div>
          <div class="option">实付款：{{orderInfo.payPrice}}</div>
          <div class="option">改价：{{orderInfo.changePrice}}</div>
          <div class="option">优惠价：{{orderInfo.couponPrice}}</div>
        </div>
      </a-timeline-item>
      <a-timeline-item v-if="orderInfo">
        <div class="headTitle">
          <p>确认退款商品</p>
        </div>
        <a-table
          class="table-template content"
          :row-selection="{
            selectedRowKeys: selectedRowKeys,
            onChange: select,
            onSelectAll: selectAll,
            columnTitle: '选择',
            getCheckboxProps: record => ({
              props: {
                disabled: disabledList.indexOf(record.id) != -1, // Column configuration not to be checked
                name: record.name,
              },
            }),
          }"
          :rowKey="(item) => item.id"
          :columns="columns"
          :data-source="tableData"
          :scroll="{ x: true }"
          :pagination="false"
        >
          <template slot="footer">
            <a-checkbox
              @change="onChange"
              :checked="isChecked"
              class="allSellect"
            >
              全选
            </a-checkbox>
          </template>
          <template slot="index" slot-scope="item, row, index">
            <!-- {{ (queryParams.pageNum - 1) * queryParams.pageSize + index + 1 }} -->
            {{ (pageNumber - 1) * pageSize + index + 1 }}
          </template>
          <template slot="productName" slot-scope="text">
            <p class="productName" :title="text">{{ text ? text : "--" }}</p>
          </template>

          <!-- 退款价格 -->
          <template slot="refundPrice" slot-scope="text, record">
            <a-input-number 
            :disabled="record.refundType == 3" 
            id="inputNumber" 
            :parser="value => `${value}`.replace(/\$\s?|(,*)/g, '')"
            :precision="2"
            :min="0" 
            :max="record.payPrice" 
            v-model="record.refundPrice"/>
          </template>

          <!-- 退款状态 -->
          <template slot="refundStatus" slot-scope="text, record">
            <a-radio-group v-model="record.refundType" @change="refundTypeChecked(record)">
              <a-radio :style="radioStyle" :value="3">
                全部退款
              </a-radio>
              <a-radio :style="radioStyle" :value="2">
                部分退款
              </a-radio>
            </a-radio-group>
          </template>

          <!-- 是否仅退款 -->
          <template slot="refundsOnly" slot-scope="text, record">
            <a-radio-group v-model="record.refundsOnly">
              <a-radio :style="radioStyle" :value="1">
                仅退款
              </a-radio>
              <a-radio :style="radioStyle" :value="0">
                退款退商品
              </a-radio>
            </a-radio-group>
          </template>

          <!-- 退款数量 -->
          <template slot="refundCount" slot-scope="text, record">
            <!-- 机构端课程商品限制最大值 -->
            <a-input-number v-if="record.productType == 1 && orderInfo.userType == 2" id="inputNumber" :disabled="record.refundType == 3" :style="{ width: 100 }" :min="1" :max="record.remendCount" v-model="record.refundCount"/>
            <a-input-number v-else id="inputNumber" :disabled="record.refundType == 3" :style="{ width: 100 }" :min="1" :max="record.productCount" v-model="record.refundCount"/>
          </template>
        </a-table>
      </a-timeline-item>
      <a-timeline-item v-if="selectedRowKeys.length">
        <div class="headTitle">
          <p>确认退款信息</p>
        </div>
        <div class="enterinfo content">
          <div class="option">
            <p>退款金额：</p>
            <span>{{refundPrice}}元</span>
          </div>
          <div class="option">
            <p>退款原因：</p>
            <a-select
              placeholder="请选择退款原因"
              style="width: 200px;height: 29px;"
              v-model="orderInfo.refundCause"
            >
              <a-icon slot="suffixIcon" type="caret-down" style="color: #3681ef" />
              <a-select-option value="书课内容"> 书课内容 </a-select-option>
              <a-select-option value="质量原因（排版、印刷等）"> 质量原因（排版、印刷等） </a-select-option>
              <a-select-option value="价格原因"> 价格原因 </a-select-option>
              <a-select-option value="重复下单"> 重复下单 </a-select-option>
              <a-select-option value="个人原因"> 个人原因 </a-select-option>
              <a-select-option value="其他"> 其他 </a-select-option>
            </a-select>
          </div>
          <div class="option">
            <p></p>
            <a-textarea v-model="orderInfo.causeExplain" placeholder="请填写退款原因（选填）" allow-clear/>
          </div>
          <div class="option">
            <p>备注：</p>
            <a-textarea v-model="orderInfo.remark" placeholder="请填写备注（非必填）" allow-clear/>
          </div>

          <!-- <div class="option">
            <p>是否换课退款：</p>
            <a-radio-group v-model="orderInfo.changeProductFlag">
              <a-radio :style="radioStyle" :value="1">
                是
              </a-radio>
              <a-radio :style="radioStyle" :value="0">
                否
              </a-radio>
            </a-radio-group>
          </div> -->
          

          <div class="option">
            <a-button class="all_btn_border btnBorder" @click="$router.go(-1)">取消</a-button>
            <a-button type="primary" style="margin-left: 20px" :loading="newLoading" @click="submit()">新建退款</a-button>
          </div>
        </div>
      </a-timeline-item>
    </a-timeline>
  </div>
</template>

<script>
const columns = [
  {
    title: "子订单编号",
    align: "center",
    width: "180px",
    dataIndex: "subCode",
  },
  {
    title: "商品名称",
    align: "center",
    dataIndex: "productName",
    width: '300px',
    scopedSlots: { customRender: "productName" },
  },
  {
    title: "数量",
    align: "center",
    width: '70px',
    dataIndex: "productCount",
  },
  {
    title: "单价",
    align: "center",
    width: '80px',
    dataIndex: "productPrice",
  },
  {
    title: "子订单金额",
    align: "center",
    width: '120px',
    dataIndex: "payPrice",
  },
  {
    title: "退款状态",
    align: "center",
    width: '250px',
    scopedSlots: { customRender: "refundStatus" },
  },
  {
    title: "退款数量",
    align: "center",
    width: '120px',
    scopedSlots: { customRender: "refundCount" },
  },
  {
    title: "退款金额",
    align: "center",
    scopedSlots: { customRender: "refundPrice" },
  },
  {
    title: "是否仅退款",
    align: "center",
    width: '250px',
    scopedSlots: { customRender: "refundsOnly" },

  }
];
export default {
  data() {
    return {
      columns, // 表头
      orderCode: "", // 订单编号
      isChecked: false,
      selectedRowKeys: [], //选择的id
      tableData: [], // 商品数据
      orderInfo: null,
      loading: false,
      newLoading: false,
      handle_Status: undefined,
      disabledList: [],
      radioStyle: {
        // display: 'block',
        height: '30px',
        lineHeight: '30px',
      },
    }
  },
  computed: {
    refundPrice() {
      console.log(this.tableData);
      
      let price = 0
      this.tableData.map(item=>{
        if(this.selectedRowKeys.indexOf(item.id) != -1) {
          if(item.refundPrice){
            price += Number(item.refundPrice)
          }
        }
      })
      
      return price.toFixed(2);
    }
  },
  methods: {
    refundTypeChecked(e) {
      if(e.refundType == 3) {
        e.refundPrice = e.payPrice
        if(e.productType == 1 && this.orderInfo.userType == 2) {
          e.refundCount = e.remendCount
        }else{
          e.refundCount = e.productCount
        }
      }
    },
    //全选
    onChange(e) {
      if (e.target.checked == true) {
        this.isChecked = true;
        let id = []
        this.tableData.map((item) => {
          if(this.disabledList.indexOf(item.id) == -1){
            id.push(item.id)
          }
        });
        this.selectedRowKeys = id;
      } else {
        this.isChecked = false;
        this.selectedRowKeys = [];
      }
    },
    select(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
      if (this.selectedRowKeys.length == this.tableData.length) {
        this.isChecked = true;
      } else {
        this.isChecked = false;
      }
    },
    selectAll(selectedRows) {
      this.selectedRows = selectedRows;
    },

    // 搜索
    search() {
      if(!this.orderCode) {
        return this.$message.warning('请先输入订单编号！')
      }
      this.selectedRowKeys = []; // 重置选中
      this.loading = true;
      this.$ajax({
        url: "/hxclass-management/refund/getOrderByCode",
        method: "get",
        params: {
          orderCode: String(this.orderCode)
        },
      }).then((res) => {
        this.loading = false;
        if (res.code == 200 && res.success) {
          res.data.refundCause = undefined;
          this.disabledList = [];
          res.data.refundVOS.map(item=>{
            // 退款价格及退款数量赋值
            item.refundPrice = item.payPrice
            if(item.productType == 1 && res.data.userType == 2) {
              item.refundCount = item.remendCount // 剩余数量 - 机构课程类型
            }else{
              item.refundCount = item.productCount // 总数量 - 除机构课程外类型
            }
            // 若已全部退款添加禁选列表
            if(item.refundStatus == 3) {
              this.disabledList.push(item.id)
            }else if(item.productType == 1 && res.data.userType == 2 && item.remendCount == 0) {
              // 机构端课程类型商品并且剩余数量为零时禁选
              this.disabledList.push(item.id)
            }
            item.refundType = 3
          })
          let list = res.data.refundVOS;
          if(list.length == 1 && this.disabledList.indexOf(list[0].id) == -1) {
            this.selectedRowKeys.push(list[0].id)
            this.isChecked = true;
          }
          this.tableData = JSON.parse(JSON.stringify(res.data.refundVOS))
          this.orderInfo = res.data
        }else{
          this.orderInfo = null
          this.$message.warning(res.message)
        }
      });
    },

    // 新建退款
    submit() {
      // 是否选择是否仅退款选项
      let isRefundsOnly = false;
      // 部分退款是否填写退款数量
      let countStatus = false;
      // 选中订单是否填写退款金额
      let status = this.tableData.some(item=>{
        if(this.selectedRowKeys.indexOf(item.id) != -1) {
          // 全部退款退款数量重置
          if(item.refundType == 3){
            // 若为机构端课程商品重置为商品剩余数量
            if(item.productType == 1 && this.orderInfo.userType == 2) {
              item.refundCount = item.remendCount
            }else{
              // 非机构端课程商品重置为商品总数量
              item.refundCount = item.productCount
            }
          }else {
            if(!item.refundCount) {
              countStatus = true;
            }
          }
          if(item.refundsOnly !== 0 && item.refundsOnly !== 1) {
            isRefundsOnly = true;
            return true;
          }
          if(!item.payPrice) {
            return false
          }
          return !item.refundPrice
        }
      })

      if(isRefundsOnly) {
        return this.$message.warning('请选择是否仅退款！')
      }

      if(countStatus){
        return this.$message.warning('请填写订单商品退款数量！')
      }
      if(status){
        return this.$message.warning('请填写订单商品退款金额！')
      }
      if(!this.orderInfo.refundCause){
        return this.$message.warning('请选择退款原因！')
      }

      this.orderInfo.refundVOS = this.tableData.filter(item=>{
        return this.selectedRowKeys.indexOf(item.id) != -1
      });
      this.newLoading = true;
      this.$ajax({
        url: "/hxclass-management/refund/add/refund",
        method: "post",
        params: this.orderInfo
      }).then((res) => {
        this.newLoading = false;
        if (res.code == 200 && res.success) {
          this.$message.success('新增成功！')
          this.$router.go(-1)
        }else{
          this.$message.warning(res.message)
        }
      });
    }
  }
}
</script>

<style scoped lang="less">
  /deep/.ant-timeline{
    padding-top: 20px;
    .content{
      margin-top: 10px;
    }
  }
  .headTitle{
    display: flex;
    align-items: center;
    // margin-top: 16px;
    // margin-bottom: 8px;
    p{
      font-size: 16px;
      color: #333333;
      line-height: 16px;
    }
    .func{
      font-size: 12px;
      color: @theme;
      margin-left: 10px;
      cursor: pointer;
    }
  }
  .find{
    display: flex;
    padding-left: 20px;
    // justify-content: space-between;
    /deep/.ant-input{
      width: 200px;
      margin-right: 20px;
    }
  }
  .info{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding-left: 20px;
    .option{
      width: 33%;
      font-size: 14px;
      margin-top: 10px;
    }
    .option:nth-child(n+4){
      margin-top: 20px;
    }
  }
  .enterinfo{
    .option{
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      p{
        width: 150px;
      }
      .ant-input{
        width: 200px;
      }
      .ant-input-affix-wrapper{
        flex: 1;
      }
    }
  }
</style>